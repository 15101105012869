/*
Copyright 2017-2024 SensiML Corporation

This file is part of SensiML™ Piccolo AI™.

SensiML Piccolo AI is free software: you can redistribute it and/or
modify it under the terms of the GNU Affero General Public License
as published by the Free Software Foundation, either version 3 of
the License, or (at your option) any later version.

SensiML Piccolo AI is distributed in the hope that it will be useful,
but WITHOUT ANY WARRANTY; without even the implied warranty of
MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the GNU
Affero General Public License for more details.

You should have received a copy of the GNU Affero General Public
License along with SensiML Piccolo AI. If not, see <https://www.gnu.org/licenses/>.
*/

export const DELETED_PROJECT = "DELETED_PROJECT";
export const DELETING_PROJECT = "DELETING_PROJECT";
export const STORE_PROJECTS = "STORE_PROJECTS";
export const STORE_SELECTED_PROJECT = "STORE_SELECTED_PROJECT";
export const STORE_LAST_SELECTED_PROJECT = "STORE_LAST_SELECTED_PROJECT";

export const LOADING_PROJECTS = "LOADING_PROJECTS";
export const LOADING_PROJECT_STATISTICS = "LOADING_PROJECT_STATISTICS";
export const STORE_PROJECT_STATISTICS = "STORE_PROJECT_STATISTICS";

export const OPTIMIZED_PROJECT = "OPTIMIZED_PROJECT";
export const UPDATING_PROJECT = "UPDATING_PROJECT";
export const UPDATED_PROJECT = "UPDATED_PROJECT";
export const DIRTY_PROJECT = "DIRTY_PROJECT";
