/*
Copyright 2017-2024 SensiML Corporation

This file is part of SensiML™ Piccolo AI™.

SensiML Piccolo AI is free software: you can redistribute it and/or
modify it under the terms of the GNU Affero General Public License
as published by the Free Software Foundation, either version 3 of
the License, or (at your option) any later version.

SensiML Piccolo AI is distributed in the hope that it will be useful,
but WITHOUT ANY WARRANTY; without even the implied warranty of
MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the GNU
Affero General Public License for more details.

You should have received a copy of the GNU Affero General Public
License along with SensiML Piccolo AI. If not, see <https://www.gnu.org/licenses/>.
*/

export default (theme) => ({
  dialogFormContainer: {
    padding: theme.spacing(2),
    width: "750px",
    // minHeight: "60vh",
    margin: "auto",
    alignItems: "center",
    "@media (max-width: 1300px)": {
      width: "auto",
    },
  },
  dialogFormWrapper: {
    display: "flex",
    flexDirection: "column",
    minHeight: "50vh",
    justifyContent: "space-between",
  },
});
