/*
Copyright 2017-2024 SensiML Corporation

This file is part of SensiML™ Piccolo AI™.

SensiML Piccolo AI is free software: you can redistribute it and/or
modify it under the terms of the GNU Affero General Public License
as published by the Free Software Foundation, either version 3 of
the License, or (at your option) any later version.

SensiML Piccolo AI is distributed in the hope that it will be useful,
but WITHOUT ANY WARRANTY; without even the implied warranty of
MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the GNU
Affero General Public License for more details.

You should have received a copy of the GNU Affero General Public
License along with SensiML Piccolo AI. If not, see <https://www.gnu.org/licenses/>.
*/

import makeStyles from "@mui/styles/makeStyles";

const useStyles = () =>
  makeStyles(() => ({
    bannerWrapper: {
      position: "relative",
      background: "#adffbed4",
      fontWeight: "500",
      color: "#384c42",
      padding: "0.5rem",
      display: "flex",
      justifyContent: "center",
      margin: "0 -1rem",
      border: "1px solid #92c59e",
      marginTop: "-1rem",
      marginBottom: "1rem",
    },
    bannerWrapperInfo: {
      position: "relative",
      background: "#cce7ff",
      fontWeight: "500",
      color: "#03446c",
      padding: "0.5rem",
      display: "flex",
      justifyContent: "center",
      border: "1px solid #0277be",
    },
    bannerWrapperInfoAfterLogin: {
      margin: "0 -1rem",
      marginTop: "-1rem",
      marginBottom: "1rem",
      paddingRight: "2rem",
    },
    bannerLink: {
      marginLeft: "0.5rem",
    },
    bannerClose: {
      cursor: "pointer",
      position: "absolute",
      fontWeight: "bold",
      right: "0.5rem",
      top: "15%",
    },
  }))();

export default useStyles;
