/*
Copyright 2017-2024 SensiML Corporation

This file is part of SensiML™ Piccolo AI™.

SensiML Piccolo AI is free software: you can redistribute it and/or
modify it under the terms of the GNU Affero General Public License
as published by the Free Software Foundation, either version 3 of
the License, or (at your option) any later version.

SensiML Piccolo AI is distributed in the hope that it will be useful,
but WITHOUT ANY WARRANTY; without even the implied warranty of
MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the GNU
Affero General Public License for more details.

You should have received a copy of the GNU Affero General Public
License along with SensiML Piccolo AI. If not, see <https://www.gnu.org/licenses/>.
*/

import React from "react";
import { Typography, Dialog, DialogContent, DialogActions } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

import useStyles from "./UIDialogFormMediumStyles";

const UIDialogForm = ({
  title,
  isOpen,
  onClose,
  actionsComponent,
  children,
  isCloseDisabled = false,
  maxWidth = "xs",
  fullWidth = true,
}) => {
  const classes = useStyles();

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      maxWidth={maxWidth}
      fullWidth={fullWidth}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogContent className={classes.dialogWrapper}>
        {!isCloseDisabled ? <CloseIcon className={classes.closeIcon} onClick={onClose} /> : null}
        <Typography id="scroll-dialog-title" variant="h2" className={classes.createDialogTitle}>
          {title}
        </Typography>
        {children}
      </DialogContent>
      <DialogActions className={classes.actionWrapper}>{actionsComponent}</DialogActions>
    </Dialog>
  );
};

export default UIDialogForm;
