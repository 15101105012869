/*
Copyright 2017-2024 SensiML Corporation

This file is part of SensiML™ Piccolo AI™.

SensiML Piccolo AI is free software: you can redistribute it and/or
modify it under the terms of the GNU Affero General Public License
as published by the Free Software Foundation, either version 3 of
the License, or (at your option) any later version.

SensiML Piccolo AI is distributed in the hope that it will be useful,
but WITHOUT ANY WARRANTY; without even the implied warranty of
MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the GNU
Affero General Public License for more details.

You should have received a copy of the GNU Affero General Public
License along with SensiML Piccolo AI. If not, see <https://www.gnu.org/licenses/>.
*/

const WIDTH_FOR_SHORT_TEXT = 1367;
const WIDTH_FOR_TABLET_TEXT = 900;
const WIDTH_FOR_PHONE_TEXT = 550;
const TRUNCATE_NAME_OVER_SHORT_TEXT = 40;
const TRUNCATE_NAME_OVER_TABLET_TEXT = 35;
const TRUNCATE_NAME_OVER_PHONE_TEXT = 19;
const TRUNCATE_NAME_OVER = 50;

export default {
  WIDTH_FOR_SHORT_TEXT,
  TRUNCATE_NAME_OVER_SHORT_TEXT,
  TRUNCATE_NAME_OVER,
  WIDTH_FOR_PHONE_TEXT,
  TRUNCATE_NAME_OVER_PHONE_TEXT,
  TRUNCATE_NAME_OVER_TABLET_TEXT,
  WIDTH_FOR_TABLET_TEXT,
};
