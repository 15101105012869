/*
Copyright 2017-2024 SensiML Corporation

This file is part of SensiML™ Piccolo AI™.

SensiML Piccolo AI is free software: you can redistribute it and/or
modify it under the terms of the GNU Affero General Public License
as published by the Free Software Foundation, either version 3 of
the License, or (at your option) any later version.

SensiML Piccolo AI is distributed in the hope that it will be useful,
but WITHOUT ANY WARRANTY; without even the implied warranty of
MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the GNU
Affero General Public License for more details.

You should have received a copy of the GNU Affero General Public
License along with SensiML Piccolo AI. If not, see <https://www.gnu.org/licenses/>.
*/

import React, { useEffect } from "react";
import AlertDialog from "components/AlertDialog";

const UnSavedChangesDialog = (props) => {
  const { openDialog, buttonOneAction, buttonTwoAction } = props;
  const [open, setOpen] = React.useState(openDialog);

  const handleButtonOneAction = () => {
    buttonOneAction();
    setOpen(false);
  };

  const handleButtonTwoAction = () => {
    buttonTwoAction();
    setOpen(false);
  };

  useEffect(() => {
    setOpen(openDialog);
  }, [openDialog]);

  return (
    <AlertDialog
      disableEscapeKeyDown
      openDialog={open}
      alertTitle="Unsaved Changes"
      // eslint-disable-next-line max-len
      dialogText="There are unsaved changes, If you leave before saving, your changes will be lost. Do you want to continue?"
      buttonOneText="Yes, Continue"
      buttonTwoText="Back"
      buttonOneAction={handleButtonOneAction}
      buttonTwoAction={handleButtonTwoAction}
    />
  );
};

export default UnSavedChangesDialog;
